import { useState } from 'react';
import './App.css';
import { send_data } from './lib';


function App() {
  const [name,set_name]=useState('');
  const [passwd,set_passwd]=useState('');

  function handle_login()
  {
    let send_par={
      "phone_number":name,
      "passwd":passwd
      }
      send_data(send_par,(e)=>{   
        console.log(e);
      });
  }

  return (
    <div className="app">
      <div className='left_side'>
          <div className='mask'></div>
          <div className='app_bottom'>            
           <a href='https://beian.miit.gov.cn'>蜀ICP备20015266号</a>
          </div>
      </div>
      <div className='right_side'>
            <div className='login_box'>
                <div className='login_title'>织慧网登录</div>
                <div className='welcome'>很高兴再次见到您！</div>
                <input value={name} onChange={(e)=>set_name(e.target.value)} placeholder='手机号' /><br></br>
                <input value={passwd} onChange={(e)=>set_passwd(e.target.value)} placeholder='登录密码' />
                <div className='do_login'>登 录</div>
            </div>
      </div>
    </div>
  );
}

export default App;
